import React from "react";
import {
  ActionButton,
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  Heading,
} from "@adobe/react-spectrum";
import "../App.css";

const Resume = () => {
  return (
    <DialogTrigger type="modal" isDismissable>
      <ActionButton>Resume</ActionButton>
      <Dialog size="L" width="size-9000">
        <Heading>Resume</Heading>
        <Divider />
        <Content>
          <iframe
            title="AndrewBastianResume"
            src="https://indd.adobe.com/embed/0e0cf819-95e7-45bb-8304-b95e96d1f0dc?startpage=1&allowFullscreen=true"
            className="resumeIframe"
            allowfullscreen=""
          ></iframe>{" "}
        </Content>
      </Dialog>
    </DialogTrigger>
  );
};

export default Resume;
