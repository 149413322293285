import React from "react";

import {
  ActionButton,
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  Heading,
  Text,
} from "@adobe/react-spectrum";
const About = () => {
  return (
    <DialogTrigger isDismissable>
      <ActionButton>About</ActionButton>
      <Dialog>
        <Heading>About Me</Heading>
        <Divider />
        <Content>
          <Text>
            Hello world, I'm a full-stack web developer and Neovim evangelist.{" "}
            <br />
            <br />
            I have been fortunate to live a life rich with experience and, along
            the way, have built a diverse set of skills. A constant throughout
            this journey has been a passion for constructing something to bring
            value to the lives of others.
            <br />
            <br />
            If I'm not doing something related to programming, I'm likely
            playing music, building a guitar, or trying to make my daughters
            laugh. If you'd like to know more, feel free to get in touch.{" "}
          </Text>
        </Content>
      </Dialog>
    </DialogTrigger>
  );
};

export default About;
